<template>
  <div class="backgroundimage">
    <div style="text-align:center; padding-top:120px">
      <div style="margin-left:20px;">
        <h2>生日快乐</h2>
      </div>
      <p>&nbsp;&nbsp;有一句话说得好，叫乐此不疲，也就是说：</p>
      <p>做自己感觉有乐趣的事情，那么自然不会感觉疲惫和劳累，</p>
      <p>快乐无国界。快乐的词汇里没有贫富，尊卑，权势之分。</p>
      <p>快乐来源于每个生灵最美好的天性。祝自己生日快乐！！！</p>
      
    </div>
    
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {};
  },
};
</script>

<style scoped>
.backgroundimage {
  background-image: url("../assets/images/梅花.jpeg");
  background-repeat: no-repeat;
  background-position: center top;
  width: 100%;
  height: 778px;
}
</style>